<template>
    <div class="flex flex-wrap ">
        <div class="form-check flex mt-1 mr-4" v-for="(option, idx) in getOptions" :key="option+idx">
            <input class="form-check-input appearance-none h-4 w-4 border border-solid border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" v-model="getValue" :value="option" :id="option + 'check' + idx" />
            <label class="form-check-label cursor-pointer inline-block text-gray-800" :for="option + 'check' + idx">
                {{ option }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
    },
    computed: {
        getOptions() {
            return this.fieldData.options || [];
        },
        getValue: {
            get() {
                return this.fieldData.value || [];
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .checked\:border-blue-600:checked {
        --border-opacity: 1;
        border-color: rgba(37, 99, 235, var(--border-opacity));
    }
    .form-check-input:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
    .form-check-input:checked[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
    .checked\:bg-blue-600:checked {
        --bg-opacity: 1;
        background-color: rgba(37, 99, 235, var(--bg-opacity));
    }
</style>